<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="design-services"
  >
    <h2 class="title">{{ slice.primary.title }}</h2>
    <p class="paragraph">{{ slice.primary.text }}</p>

    <Swiper
      v-show="isMobile"
      :speed="500"
      class="swiper"
      :slides-per-view="1.3"
      :space-between="13"
      :breakpoints="{
        767: {
          slidesPerView: 1.4,
        },
      }"
    >
      <!-- :free-mode="true"
      :modules="[SwiperFreeMode]" -->
      <template v-for="slide in slice.primary.service">
        <SwiperSlide class="swiper-slide">
          <NuxtLink :to="slide.url">
            <div class="service-item">
              <div class="service-item--image">
                <CustomImage :image="slide.image" :sizes="[306, 448]" />
              </div>
              <p class="service-item--title">{{ slide.title }}</p>
              <p class="service-item--text">{{ slide.text }}</p>
            </div>
          </NuxtLink>
        </SwiperSlide>
      </template>
    </Swiper>

    <div v-show="!isMobile" class="service-items">
      <template v-for="(slide, index) in slice.primary.service">
        <div
          class="service-item"
          @mouseenter="showCustomCursor(index)"
          @mouseleave="hideCursor()"
        >
          <NuxtLink :to="slide.url">
            <div class="service-item--image">
              <CustomImage :image="slide.image" :sizes="[306, 448]" />
            </div>
            <p class="service-item--title">{{ slide.title }}</p>
            <p class="service-item--text">{{ slide.text }}</p>
          </NuxtLink>
        </div>
      </template>
    </div>
  </section>
</template>

<script setup lang="ts">
import { type Content } from "@prismicio/client";

import { useMobile } from "@/composables/useMobile";
const { isMobile } = useMobile();

const { showCursor, hideCursor } = useCustomCursor();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
  getSliceComponentProps<Content.DesignServicesHomepageSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);

const showCustomCursor = (index) => {
  const wordings = [
    "Complimentary Samples",
    "Designer Directory",
    "Care & Maintenance",
  ];

  showCursor(wordings[index]);
};

onBeforeUnmount(() => {
  hideCursor();
});

onUnmounted(() => {
  hideCursor();
});
</script>

<style
  src="@/assets/css/slices/homepage/design-services.scss"
  lang="scss"
  scoped
></style>
